/*------------------------------------
  Loading State
------------------------------------*/

.hs-loader-wrapper {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  display: none;
  background-color: $loader-wrapper-bg-color;
  @include border-radius($loader-wrapper-border-radius);
}

.hs-loader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}
