/*------------------------------------
  Button Soft
------------------------------------*/

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    @include button-soft-variant($value, $value, transparent, $value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    &.dropdown-toggle::after {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='#{$value}' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>")
      );
      transition: $btn-transition;
    }

    .btn-check:focus + &,
    &:hover,
    &:focus {
      &.dropdown-toggle::after {
        background-image: escape-svg(
          url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='#{(color-contrast($value))}' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>")
        );
      }
    }
  }
}

// Soft Secondary
.btn-soft-secondary {
  .btn-check:focus + &,
  &:hover,
  &:focus {
    color: $white;

    &.dropdown-toggle::after {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='#{$white}' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>")
      );
    }
  }
}
