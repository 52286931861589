/*------------------------------------
  Form Check Select Stretched
------------------------------------*/

.form-check-select-stretched {
  padding-left: 0;
  margin-bottom: 0;

  .form-check-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    float: none;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;
    border: $form-check-select-stretched-border-width solid
      $form-check-select-stretched-border-color;
    @include border-radius($card-border-radius);

    &::before {
      position: absolute;
      top: $form-check-select-stretched-input-pseudo-top-offset;
      right: $form-check-select-stretched-input-pseudo-top-offset;
      width: $form-check-select-stretched-input-pseudo-width;
      height: $form-check-select-stretched-input-pseudo-height;
      background-image: escape-svg($form-check-select-stretched-input-bg-image);
      background-repeat: no-repeat;
      background-position: right center;
      background-size: $form-check-select-stretched-input-pseudo-width
        $form-check-select-stretched-input-pseudo-height;
      content: '';
    }
  }

  .form-check-input {
    &[type='checkbox'],
    &[type='radio'] {
      background-color: transparent;
      border-width: 0;
      background-image: none;
    }
  }

  .form-check-input {
    &:checked {
      &[type='checkbox'],
      &[type='radio'] {
        ~ .form-check-label {
          border-color: $form-check-select-stretched-border-active-color;

          &::before {
            background-image: escape-svg(
              $form-check-select-stretched-input-checked-bg-image
            );
          }
        }
      }
    }
  }

  &.checked {
    .form-check-select-stretched-btn {
      color: $form-check-select-stretched-btn-active-color;
      background-color: $form-check-select-stretched-btn-bg-active-color;
      border-color: $form-check-select-stretched-btn-border-active-color;
    }
  }
}
