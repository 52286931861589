/*------------------------------------
  Button Groups
------------------------------------*/

// Segment
.btn-group-segment {
  position: relative;
  display: inline-flex;
  justify-content: center;
  background-color: $btn-group-segment-bg-color;
  @include border-radius($btn-group-segment-border-radius);
  padding: $btn-group-segment-padding-y $btn-group-segment-padding-x;

  .btn {
    color: $btn-group-segment-link-color;

    &:not(:last-child) {
      margin-right: $btn-group-segment-link-margin-x;
    }

    &:hover {
      color: $btn-white-hover-color;
    }
  }

  > .btn:not(:last-child):not(.dropdown-toggle),
  > .btn-group:not(:last-child) > .btn,
  > .btn:nth-child(n + 3),
  > :not(.btn-check) + .btn,
  > .btn-group:not(:first-child) > .btn {
    @include border-radius($btn-group-segment-border-radius);
  }

  > .btn-check:checked + .btn,
  > .btn-check:focus + .btn {
    color: $btn-group-segment-link-active-color;
    background-color: $btn-group-segment-link-active-bg-color;
    box-shadow: $btn-group-segment-link-active-box-shadow;
  }

  // Pills
  &.btn-group-pills {
    @include border-radius($btn-group-pills-segment-border-radius);

    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn,
    > .btn:not(:first-child),
    > .btn-group:not(:first-child) > .btn {
      @include border-radius($btn-group-pills-segment-link-border-radius);
    }
  }
}

// Fill
.btn-group-fill {
  display: flex;
}

// Vertical
@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  .btn-group#{$infix}-vertical {
    @include media-breakpoint-down($breakpoint) {
      > .btn,
      > .btn-group,
      > .btn-group > .btn {
        width: 100%;
        display: block;
      }

      > .btn-group > .btn-check:not(:first-child) + .btn {
        margin-top: -$btn-border-width;
      }

      .btn-group > .btn:not(:first-child),
      .btn-group > .btn-group:not(:first-child) {
        margin-left: 0;
      }

      // Reset rounded corners
      > .btn-group > .btn-check + .btn {
        @include border-radius(0);
      }

      > .btn-group > .btn-check:first-child + .btn {
        @include border-top-radius($btn-border-radius);
      }

      > .btn-group > .btn:last-child {
        @include border-bottom-radius($btn-border-radius);
      }

      > .btn-group-segment > .btn-check + .btn {
        @include border-radius($btn-border-radius);
      }
    }
  }
}
