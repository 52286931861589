/*------------------------------------
  Form Text
------------------------------------*/

.form-text {
  margin-bottom: 0;
}

.form-green-highlight {
  color: green;
  font-weight: bold;
}
