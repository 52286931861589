.cart-create-form {
  .dz-img {
    max-width: 100%;
  }
  .dz-progress {
    display: none;
  }
  .dz-error-mark,
  .dz-success-mark {
    display: none;
  }
  .dz-error-message {
    color: red;
  }
  .iti--allow-dropdown {
    width: 100%;
  }
  .select2-container {
    width: 100% !important;
  }

  .link {
    color: #007bff;
    cursor: pointer;
  }
}
