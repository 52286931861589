/*------------------------------------
  Content
------------------------------------*/

.content-space {
  padding: $content-space-padding;

  // Small Devices
  @include media-breakpoint-down(sm) {
    padding: calc($content-space-padding / 2);
  }
}

.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      &#{$infix} {
        .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
          padding-left: $navbar-brand-width;
        }
      }
    }
  }
}

.footer-offset {
  margin-bottom: $footer-height;
}

.main {
  flex-grow: 1;

  .content {
    padding-top: $content-padding-y;
    padding-bottom: $content-padding-y;
  }
}

.container-fluid {
  padding-right: var(--bs-gutter-x, 2rem);
  padding-left: var(--bs-gutter-x, 2rem);

  // Small Devices
  @include media-breakpoint-down(sm) {
    padding-right: var(--bs-gutter-x, 1.25rem);
    padding-left: var(--bs-gutter-x, 1.25rem);
  }
}
