/*------------------------------------
  Form Check
------------------------------------*/

.form-check-label {
  margin-top: $form-check-margin-y;
}

.form-check {
  .form-check-label {
    margin-top: 0;
  }
}

.form-check-input {
  margin-top: ($line-height-base - $form-check-input-width) * 0.375; // line-height minus check height
}

.form-check-text {
  display: block;
}

.form-check-img {
  max-width: 100%;
  height: auto;
}

label.form-control,
.form-check-input,
.form-check-label {
  cursor: pointer;
}

.form-check-input:focus {
  box-shadow: none;
}

// Default/Active Icon
.form-check-input ~ .form-check-label {
  .form-check-default {
    display: block;
  }

  .form-check-active {
    display: none;
  }
}

.form-check-input:checked ~ .form-check-label {
  .form-check-default {
    display: none;
  }

  .form-check-active {
    display: block;
  }
}

// Form Check Reverse
.form-check-reverse {
  padding-left: 0;
  padding-right: $form-check-padding-start;

  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }
}

// Validation
.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border: $form-check-input-border;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: $form-check-label-color;
}

// Styles
@each $color, $value in $theme-colors {
  .form-check-#{$color} {
    .form-check-input {
      &:checked {
        background-color: $value;
        border-color: $value;
      }
    }
  }
}
