.company-settings-form {
  .logo {
    margin: 0px;
  }
  .company-logo-wrapper {
    display: flex;
  }
  .company-logo-uploader {
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
    .logo-uploader-input {
      display: none;
    }
    .js-file-attach-reset-img {
      margin-left: 1rem;
    }
  }
}
