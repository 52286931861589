/*------------------------------------
  Input Group
------------------------------------*/

.input-group-append,
.input-group-prepend {
  display: flex;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  @include border-start-radius(0);
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  @include border-end-radius(0);
}

// Small Devices
@include media-breakpoint-up(sm) {
  .input-group-append-last-sm-down-none .tom-select-custom .form-select {
    @include border-end-radius(0);
  }
}

// Small Devices
@include media-breakpoint-down(sm) {
  .input-group-append-last-sm-down-none.input-group-append:last-child
    .form-select:not(:last-child) {
    @include border-end-radius($input-border-radius);
  }
}
