/*------------------------------------
  Text Highlight
------------------------------------*/

[class*='text-highlight-'] {
  background: $text-highlight-bg-value;
  background-repeat: repeat-x;
}

@each $color, $value in $theme-colors {
  .text-highlight-#{$color} {
    @include gradient-y(
      $start-color: rgba($value, 0.5),
      $end-color: rgba($value, 0.5)
    );
  }
}
