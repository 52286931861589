/*------------------------------------
  Input Group Flush
------------------------------------*/

.form-select-flush,
.input-group-flush {
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none;
  padding-right: 0;
  padding-left: 0;
  @include border-radius(0);

  &.is-valid,
  &.is-invalid {
    &,
    &:focus {
      box-shadow: none;
    }
  }
}

.form-control-flush:focus {
  box-shadow: none;
}

.input-group-flush {
  .form-select,
  .form-control {
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
    padding-right: 0;
    padding-left: 0;
    @include border-radius(0);

    &.is-valid,
    &.is-invalid {
      &,
      &:focus {
        box-shadow: none;
      }
    }
  }

  .input-group-append,
  .input-group-prepend {
    &.input-group-text {
      border-top: none;
      border-right: none;
      border-left: none;
      @include border-radius(0);
    }
  }

  .input-group-prepend {
    &.input-group-text {
      padding-left: 0;
    }
  }

  .input-group-append {
    &.input-group-text {
      padding-left: 0;
    }
  }

  &.input-group-merge {
    .input-group-append,
    .input-group-prepend {
      &.input-group-text {
        padding: 0;
      }
    }

    .form-select,
    .form-control {
      &:not(:first-child) {
        padding-left: $input-padding-x * 2;
      }

      &:not(:last-child) {
        padding-right: $input-padding-x * 2;
      }
    }
  }

  &.input-group .form-control:not(:first-child),
  &.input-group .form-control:not(:last-child),
  &.input-group-merge .input-group-prepend.input-group-text,
  &.input-group-merge .input-group-append.input-group-text,
  &.input-group .input-group-append.input-group-text:first-child,
  &.input-group .input-group-append.input-group-text:last-child,
  &.input-group .form-select:not(:first-child),
  &.input-group .form-select:not(:last-child) {
    @include border-radius(0);
  }
}
