.upload-relationship {
  .dz-img {
    max-width: 100%;
  }
  .dz-progress {
    display: none;
  }
  .dz-error-mark,
  .dz-success-mark {
    display: none;
  }
  .dz-error-message {
    color: red;
  }
}
