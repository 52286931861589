@import 'carts/order_create_form';
@import 'carts/upload_relationship';
@import 'carts/guest_create';

.file-preview {
  max-width: 100%;
  height: 300px;
  .preview-text {
    font-size: 12px;
    margin-top: -1.5rem;
  }
  .bi-file-earmark-text {
    font-size: 130px;
  }
}
